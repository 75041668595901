export const setToken = (token: string) => {
    localStorage.setItem('token', token);
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const statuses: Status[] = [
    {
        status: '🔴 Urgent',
        twColor: 'bg-red-500',
        twHover: 'hover:bg-red-400',
        twSelected: 'bg-red-300',
    },
    {
        status: '🟢 Current',
        twColor: 'bg-green-500',
        twHover: 'hover:bg-green-400',
        twSelected: 'bg-green-300',
    },
    {
        status: '🟡 Pending QA',
        twColor: 'bg-yellow-500',
        twHover: 'hover:bg-yellow-300',
        twSelected: 'bg-yellow-300',
    },
    {
        status: '🔵 Pending CMF',
        twColor: 'bg-blue-500',
        twHover: 'hover:bg-blue-400',
        twSelected: 'bg-blue-300',
    },
    {
        status: '🟣 Complete',
        twColor: 'bg-purple-500',
        twHover: 'hover:bg-purple-400',
        twSelected: 'bg-purple-300',
    },
    {
        status: '⚫ Inactive',
        twColor: 'bg-gray-500',
        twHover: 'hover:bg-gray-400',
        twSelected: 'bg-gray-300',
    },
];

export const prStatuses = ['🟡 Pending Approval', '🟣 Merged', '🔴 Conflicts / Errors', '⚫ Abandoned'];

export const getStatus = (status: string) => {
    let toReturn: Status | undefined;
    statuses.forEach(s => {
        if (s.status === status) toReturn = s;
    });
    return toReturn;
};

export const statusesToStrings = () => {
    let statusStrings: string[] = [];
    statuses.forEach(status => statusStrings.push(status.status));
    return statusStrings;
};

export const allRepos: string[] = [
    'IBE', 'AccountOnboardingService', 'AccountsService', 'ACHService', 'AFCUService', 'AlertServer-WebDept',
    'AlertsService', 'AppPeripheralsService', 'ApprovedAccountsService', 'AuthenticationService',
    'BillsService', 'CardControlService', 'CardsService', 'Codebase', 'CopernicusService', 'CustomersService',
    'DepositsService', 'DocumentsService', 'EFTWiresService', 'EquifaxEIDVerifier', 'FPSWebCDN', 'IBEApp', 'IBEComDlls', 'IBEFlutter',
    'InitialPassword', 'IntegrationTests', 'InternalClientService', 'LoansService', 'LoggingService', 'NewAccountsService',
    'OrchardModules', 'OrchardThemes', 'PermissionsService', 'PositivePayService', 'PublicApiService', 'RemoteDepositService',
    'ReportsService', 'RiskAssessmentService', 'securefps-gold', 'SecureMessageService', 'ServiceBusUtilities', 'ServiceUtilities',
    'TextBankingService', 'TrainingProjects', 'TransfersService', 'VerafinActivityLog', 'WebSettingsService', 'WebUserService', 'WiresService',
];